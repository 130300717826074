<template>
  <tr class="table-row">
    <td class="text-center" width="60px">
      <strong>{{ data.startDate | date('HH:mm') }}</strong>
    </td>
    <td class="text-center" width="45px">
      <dx-dropdown :items="statuses" label="name"
                   class="input-group-addon appointment-status"
                   @select="setStatus"
                   :disabled="data.status === 'blocked' || data.status === 'available'"
                   :max-height="350">
        <span class="square c-hand" :title="statusName"
            :style="{backgroundColor: statusColor}"></span>
        <template v-slot:item="{ item }">
          <div class="tile tile-centered c-hand">
            <div class="tile-icon">
              <span class="square" :style="{backgroundColor: item.color}"></span>
            </div>
            <div class="tile-content">{{ item.name }}</div>
          </div>
        </template>
      </dx-dropdown>
      <div v-if="data.status === 'waiting' && data.arrivedAt">
        <small :class="setStatusColor()">
          {{ $timeDiff(data.arrivedAt, now, 'HH:mm') }}
        </small>
      </div>
    </td>
    <td style="width: 30%;" class="text-ellipsis"
        @click="openMedicalRecord(data.patient.id)"
        :class="data.status !== 'blocked' ? 'c-hand' : ''">
      <span>{{ data.patient.name }}</span>
      <span v-if="!data.patient.id" class="text-warning">
        (não cadastrado)
      </span><br>
      <small>{{ data.patient.birthDate | dateOld(true) }}</small><br>
      <small>{{ (data.patient.cellphone || data.patient.phone) | phone }}</small>
      <div class="text-warning"><small>{{ data.notes }}</small></div>
    </td>
    <td class="text-ellipsis hide-sm"
        :class="data.status !== 'blocked' ? 'c-hand' : ''"
        @click="openMedicalRecord(data.patient.id)">
      <div v-if="data.insurance">
        <span>{{ data.insurance.name }}</span><br>
        <small>{{ types.getName(data.type) }}</small>
      </div>
      <small v-if="expenseNames.length">
        <span class="text-info">{{ expenseNames[0] }}</span>
        <span
          v-if="expenseNames.length > 1"
          class="label text-primary tooltip hover-link ml-1 "
          :data-tooltip="expenseNames.slice(1).join('\n')">
          +{{ expenseNames.length -1 }}
        </span>
      </small>
    </td>
    <td class="text-center hide-sm hide-md" width="50px">
      <router-link
        :disabled="!data.patient.id"
        :to="`/patients/${data.patient.id}/edit`"
        class="btn btn-action btn-icon btn-gray btn-sm tooltip tooltip-left"
        data-tooltip="Cadastro do paciente">
        <fa-icon :icon="['fal', 'user']"></fa-icon>
      </router-link>
    </td>
  </tr>
</template>

<script>
import moment from 'moment';
import {
  mapActions,
  mapState,
} from 'vuex';
import appointmentStatuses,
{
  getColor,
  getName,
} from 'src/data/appointment-statuses';
import * as types from 'src/data/appointment-types';

export default {
  props: {
    data: {},
  },
  data() {
    return {
      statuses: appointmentStatuses,
      types,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      now: ({ now }) => now,
      user: ({ auth }) => auth.user,
    }),
    expenseNames() {
      return this.data.expenses
        .map(expense => `${expense.code} - ${expense.name}`)
        .sort();
    },
    statusColor() {
      return getColor(this.data.status);
    },
    statusName() {
      return getName(this.data.status);
    },
  },
  methods: {
    ...mapActions({
      loadCalendar: 'loadCalendar',
    }),
    attachment() {
      this.$emit('attachment', {
        appointmentId: this.data.id,
        patientId: this.data.patient.id,
      });
    },
    setStatus({ key }) {
      const params = {
        status: key,
      };
      if (key === 'waiting') {
        params.arrivedAt = moment().format('YYYY-MM-DDTHH:mm');
        this.data.arrivedAt = params.arrivedAt;
      } else if (!['in_attendance', 'finished', 'report', 'payment', 'screening'].includes(key)) {
        params.arrivedAt = null;
        this.data.arrivedAt = null;
      }
      this.updateAppointment(this.data.id, params)
        .catch(() => {});
      this.data.status = key;
    },
    setStatusColor() {
      const timeDiff = this.$timeDiff(this.data.arrivedAt, this.now, 'HH:mm');
      if (timeDiff > '00:09' && timeDiff < '00:20') {
        return 'text-error';
      }
      if (timeDiff > '00:20') {
        return 'text-error text-bold';
      }
      return 'text-dark';
    },
    updateAppointment(id, data) {
      return this.$http.put(`/appointments/${id}`, data);
    },
    openMedicalRecord(id) {
      this.allowAttendance();

      if (!id) {
        return;
      }

      this.$router.push(`/patients/${id}/medical-records?appointmentId=${this.data.id}`);
    },
    openMedicalReport(appointmentId) {
      this.allowAttendance();

      this.loading = true;

      return this.$http
        .get(`/medical-reports/appointment/${appointmentId}`)
        .then(({ data }) => {
          if (data.id) {
            this.$router
              .push(`/medical-reports/${data.id}`);
          }
        })
        .catch((e) => {
          if (e.response
            && e.response.data
            && e.response.data.message) {
            this.$toast.show(e.response.data.message, { type: 'error' });
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    allowAttendance() {
      if (this.data.status !== 'waiting'
        && this.data.status !== 'finished'
        && this.data.status !== 'in_attendance'
        && this.data.status !== 'screening'
        && this.data.status !== 'report'
        && this.user.branch.settings
        && this.user.branch.settings['clinical.blockServiceWithoutAccount']) {
        this.$toast.show('Atenção. Este paciente ainda não foi autorizado na recepção!', {
          type: 'error',
          timeout: 5000,
        });
      }
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/variables';
  .table-row {
    .appointment-status {
      &.dropdown > a {
        display: block;
        margin-top: .05rem;
        &:focus {
          box-shadow: none;
        }
      }
      .tile-content {
        line-height: $line-height;
        text-align: left;
        white-space: nowrap;
      }
    }
  }
</style>
